import {Button, Dropdown, DropdownButton, Form, Modal, Table} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {Loader} from "../Loader/Loader";
import React, {Fragment, useEffect, useMemo, useState} from "react";
import {FW_DEVICES} from "../../models/AwpFwUpdateDevice";
import {formatAwpFwDeviceName, getAwpFwDeviceCloudType} from "../../helpers/AwpFwFormatHelper";
import "./FwSelectDialog.css"
import {collection, getDocs, query} from "firebase/firestore";
import {firebaseStorage, firestore} from "../../index";
import {AwpFwInfo} from "../../models/AwpFwInfo";
import {getDownloadURL, ref} from "firebase/storage";
import {logError} from "../../helpers/LogHelper";
import {GSP, GSPS, NVP, NVT, ZIP} from "../../models/AwpFwFileType";
import {SERVICE_MODE_DISABLED, SERVICE_MODE_PRODUCTION, SERVICE_MODE_QC} from "../../helpers/ServiceModeHelper";

interface DeviceSelection {
    deviceType: number;
    displayName: string;
    deviceCloudType: string;
}

interface Message {
    text: string;
    isLoader: boolean;
}

interface Props {
    show: number;
    closeHandler: () => void;
    fwSelectionHandler: (fwInfo: AwpFwInfo, fileName: string, fileType: number, content: ArrayBuffer) => void;
    deviceType?: number;
    serviceMode: string;
}

function fetchFirmwares(serviceMode: string): Promise<Array<AwpFwInfo> | null> {
    const fwCollectionRef = collection(firestore, "firmwares");
    const fwQuery = query(fwCollectionRef);
    // switch (serviceMode) {
    //     case SERVICE_MODE_QC:
    //         fwQuery = query(fwCollectionRef);
    //         break;
    //     case SERVICE_MODE_PRODUCTION:
    //         fwQuery = query(fwCollectionRef,
    //             where("status", "!=", "fail"),
    //             orderBy("status", "desc"));
    //         break;
    //     case SERVICE_MODE_DISABLED:
    //     default:
    //         fwQuery = query(fwCollectionRef,
    //             where("status", "==", "release"));
    //         break
    // }
    return getDocs(fwQuery).then(snapshot => {
        const fwData = new Array<AwpFwInfo>();
        snapshot.forEach(doc => {
            const docData = doc.data();
            if ((serviceMode === SERVICE_MODE_QC) || (serviceMode === SERVICE_MODE_PRODUCTION && docData.status !== "fail") || (serviceMode === SERVICE_MODE_DISABLED && docData.status === "release")) {
                fwData.push({
                    deviceType: docData.deviceType,
                    sw: docData.SW,
                    swVersion: docData.SWVersion,
                    hwMin: docData.HWmin,
                    hwMax: docData.HWmax,
                    bootloader: docData.bootloader,
                    type: docData.type,
                    changeLog: docData.changeLog,
                    status: docData.status,
                    fileId: doc.id,
                } as AwpFwInfo);
            }
        });
        return fwData;
    }).catch(() => {
        return null;
    });
}

export function FwSelectDialog(props: Props) {
    const {t} = useTranslation();
    const [message, setMessage] = useState(undefined as Message | undefined)
    const [firmwares, setFirmwares] = useState(undefined as Array<AwpFwInfo> | null | undefined);
    useEffect(() => {
        if (props.show % 2 === 1) {
            setMessage({text: t("awp_fw_device_version_awaiting_data"), isLoader: true})
            fetchFirmwares(props.serviceMode).then(fw => setFirmwares(fw));
        }
    }, [t, props.show]);
    const deviceSelections: DeviceSelection[] = useMemo(() => {
        return FW_DEVICES.map(d => {
            return {
                deviceType: d,
                displayName: formatAwpFwDeviceName(t, d),
                deviceCloudType: getAwpFwDeviceCloudType(d)
            }
        });
    }, [t]);
    const [deviceSelection, setDeviceSelection] = useState(deviceSelections[0]);
    const [showLatest, setShowLatest] = useState(true);
    useEffect(() => {
        if (props.deviceType) {
            const selection = deviceSelections.find(s => s.deviceType === props.deviceType);
            if (selection) {
                setDeviceSelection(selection);
            }
        }
    }, [props.deviceType]);
    const filteredFirmwares = useMemo(() => {
        if (firmwares) {
            setMessage(undefined)
            const awpFwInfos = firmwares.filter(fw => fw.deviceType === deviceSelection.deviceCloudType).sort((a, b) => a.sw < b.sw ? 1 : -1);
            if (showLatest) {
                const latestAwpFwInfos: AwpFwInfo[] = [];
                for (const info of awpFwInfos) {
                    latestAwpFwInfos.push(info);
                    for (const innerInfo of awpFwInfos) {
                        if (info === innerInfo) {
                            continue;
                        }
                        if (info.hwMin >= innerInfo.hwMin && info.hwMax <= innerInfo.hwMax) {
                            if (info.sw < innerInfo.sw) {
                                latestAwpFwInfos.pop()
                                break;
                            }
                        }
                    }
                }
                return latestAwpFwInfos
            } else {
                return awpFwInfos;
            }
        } else {
            setMessage({text: t("awp_fw_download_failed"), isLoader: false});
            return firmwares;
        }
    }, [firmwares, deviceSelection, showLatest, t]);
    const selectFw = (fwInfo: AwpFwInfo) => {
        setMessage({text: t("awp_fw_downloading"), isLoader: true});
        if (fwInfo) {
            const fileRef = ref(firebaseStorage, `Firmwares/${fwInfo.fileId}`);
            getDownloadURL(fileRef)
                .then(url => fetch(url))
                .then(async response => {
                    if (response.ok) {
                        try {
                            const fwData = await response.arrayBuffer();
                            let fwType;
                            switch (fwInfo.type) {
                                case "gsp":
                                    fwType = GSP;
                                    break;
                                case "gsps":
                                    fwType = GSPS;
                                    break;
                                case "nvt":
                                    fwType = NVT;
                                    break;
                                case "nvp":
                                    fwType = NVP;
                                    break;
                                case "zip":
                                    fwType = ZIP;
                                    break;
                                default:
                                    throw new Error("Unsupported file");
                            }
                            let device = fwInfo.deviceType.toUpperCase();
                            if (device === "LEEB_PLAIN") {
                                device = "LEEB_LITE";
                            }
                            const version = fwInfo.swVersion ?? fwInfo.sw.toString().replaceAll(".", "_");
                            const type = fwInfo.type;
                            const name = `${device}_SW_${version}.${type}`;
                            props.fwSelectionHandler(fwInfo, name, fwType, fwData);
                            close();
                        } catch (e) {
                            logError("Response process error", e);
                            setMessage({text: t("awp_fw_download_failed"), isLoader: false});
                        }
                    }
                }).catch(() => setMessage({text: t("awp_fw_download_failed"), isLoader: false}));
        }
    }
    const close = () => {
        props.closeHandler();
    }
    return (
        <Modal show={props.show % 2 === 1} size={"xl"} onHide={close} dialogClassName="fw-select-modal">
            <Modal.Header closeButton>
                <Modal.Title>{t("loader_cloud_select_fw")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {message &&
                    <Fragment>
                        {message.isLoader && <Loader className={"mt-4"} message={message.text}/>}
                        {!message.isLoader && <div>{message.text}</div>}
                    </Fragment>
                }
                {!message && filteredFirmwares &&
                    <div className="d-flex flex-column justify-content-start">
                        <Form.Label>{t("device")}</Form.Label>
                        <div className="d-flex flex-row align-items-center">
                            <DropdownButton size="sm" className="w-100" title={deviceSelection.displayName}
                                            disabled={props.deviceType !== undefined}>
                                {deviceSelections.map(s => <Dropdown.Item key={`ds-${s.deviceType}`}
                                                                          onClick={() => setDeviceSelection(s)}>{s.displayName}</Dropdown.Item>)}
                            </DropdownButton>
                            <Form.Check type={"checkbox"}
                                        label={t("loader_show_only_latest_fw")}
                                        checked={showLatest}
                                        onChange={() => setShowLatest(!showLatest)}/>
                        </div>
                        {filteredFirmwares.length === 0 &&
                            <div className="m-4 text-center">{t("loader_cloud_no_fw")}</div>}
                        {filteredFirmwares.length > 0 &&
                            <Fragment>
                                <Table striped bordered className="fw-table mt-4 mb-0">
                                    <thead>
                                    <tr>
                                        <th>{t("loader_fw_version")}</th>
                                        <th>{t("loader_min_hw")}</th>
                                        <th>{t("loader_max_hw")}</th>
                                        <th>{t("loader_fw_type")}</th>
                                        <th>{t("loader_bootloader")}</th>
                                        {(props.serviceMode === SERVICE_MODE_QC || props.serviceMode === SERVICE_MODE_PRODUCTION) &&
                                            <th>{t("loader_status")}</th>}
                                        <th/>
                                        {filteredFirmwares.length > 7 && <th style={{width: "15px"}}/>}
                                    </tr>
                                    </thead>
                                </Table>
                                <div style={filteredFirmwares.length > 7 ? {
                                    height: "400px",
                                    overflowY: "scroll"
                                } : {}}>
                                    <Table striped bordered className="fw-table mb-0">
                                        <tbody>
                                        {filteredFirmwares.map(fw =>
                                            <tr key={`{fw-${fw.fileId}`}>
                                                <td>{fw.swVersion ?? fw.sw}</td>
                                                <td>{fw.hwMin}</td>
                                                <td>{fw.hwMax}</td>
                                                <td>{fw.type}</td>
                                                <td>{fw.bootloader}</td>
                                                {(props.serviceMode === SERVICE_MODE_QC || props.serviceMode === SERVICE_MODE_PRODUCTION) &&
                                                    <td>{fw.status}</td>}
                                                <td><Button className="btn btn-primary btn-sm"
                                                            onClick={() => selectFw(fw)}>{t("select")}</Button>
                                                </td>
                                            </tr>
                                        )}
                                        </tbody>
                                    </Table>
                                </div>
                            </Fragment>
                        }
                    </div>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={close}>{t("cancel")}</Button>
            </Modal.Footer>
        </Modal>
    );
}
